<!--
 * @Author: zengzewei
 * @Date: 2021-11-24 09:59:39
 * @LastEditTime: 2021-12-10 17:10:22
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \onesports-arc-view\src\views\venueMana\guestBook\index.vue
-->
<template>
    <div class="wrap">
        <div class="search-container">
            <el-row>
                <el-col :span="20">
                    <el-row>
                        <el-form ref="form" :model="form" label-width="90px">
                            <el-col :span="8">
                                <el-form-item label="选择时间：">
                                    <el-date-picker
                                        v-model="form.time"
                                        type="daterange"
                                        :clearable="true"
                                        style="width:100%"
                                        value-format="yyyy-MM-dd"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="手机号：">
                                    <el-input v-model="form.phone" placeholder="请输入手机号" :clearable="true"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="处理结果：">
                                    <el-select v-model="form.state" placeholder="请选择处理结果" :clearable="true" style="width:100%">
                                        <el-option label="已处理" value="1"></el-option>
                                        <el-option label="未处理" value="0"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-form>
                    </el-row>
                </el-col>
                <el-col :span="4" class="btn">
                    <el-button  class="search-button" @click="searchEvent">{{$t("search")}}</el-button>
                    <el-button  class="clean-button" @click="clearnForm">{{$t("clean")}}</el-button>
                </el-col>
            </el-row>
        </div>
        <div class="table-container">
            <!-- :span-method="arraySpanMethod" -->
            <el-table
                :data="tableData"
                :height="tableHeight + 50"
                ref="multipleTable"
                style="width: 100%"
                border
                :row-class-name="tableRowClassName"
            >
                <el-table-column type="selection" align="center" width="55">
                </el-table-column>
                <el-table-column type="index" align="center" label="序号" width="55">
                </el-table-column>
                <el-table-column
					prop="createTime"
                    min-width="150"
                    align="center"
					label="留言时间">
				</el-table-column>
                <el-table-column
					prop="context"
                    min-width="250"
                    align="center"
					label="留言内容">
				</el-table-column>
                <el-table-column
					prop="phone"
                    min-width="100"
                    align="center"
					label="手机号">
				</el-table-column>
                <el-table-column
					prop="state"
                    min-width="80"
                    align="center"
					label="处理结果"
                    :formatter="typeFormatter"
                    >
				</el-table-column>
            </el-table>
        </div>
        <div class="page-warp" >
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="form.pageNum"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="form.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="form.total"
                    >
                    </el-pagination>
                    <el-button class="default-button" @click="openOperation(true)" v-if="buttonAuth.includes('venueOperation:message:deal')">操作</el-button>
        </div>
        <process-result-dialog
            :show="dialogVisible"
            :id="guestId"
            @closeDialog="closeDialog"
        >
        </process-result-dialog>
    </div>
</template>

<script>
import mixin from "@/mixins/index";
import processResultDialog from './dialog/processResultDialog.vue'
import apis from "@/apis";

export default {
    mixins: [mixin],
    components: {
        processResultDialog,
  },
    data() {
        return {
            form: {
                time: '',
                phone: '',
                state: '',
                leaveTimeBegan: '',
                leaveTimeOver: '',
                pageNum: 1,
                pageSize: 10,
                total: 0,
            },
            tableData: [],
            dialogVisible: false,
            guestId: '',
        };
    },

    methods: {
        typeFormatter(row, column, cellValue) {
            let string = ''
            if (cellValue === '1') {
                string = '已处理'
            } else if (cellValue === '0') {
                string = '未处理'
            }
            return string
        },
        openOperation(dialogStatus) {
            console.log(this.$refs.multipleTable.selection)
            console.log(dialogStatus)
            if (this.$refs.multipleTable.selection.length > 1) {
                this.$message.error("不可选中多条数据");
            } else {
                this.guestId = this.$refs.multipleTable.selection[0].id
                this.dialogVisible = dialogStatus
            }
        },
        closeDialog(dialogStatus) {
             this.dialogVisible = dialogStatus
             this.searchEvent()
        },
        searchData() {
            console.log(this.form.time)
            if (this.form.time && this.form.time.length > 0) {
                this.form.leaveTimeBegan = this.form.time[0]
                this.form.leaveTimeOver = this.form.time[1]
            } else {
                this.form.leaveTimeBegan = ''
                this.form.leaveTimeOver = ''
            }
            // console.log(this.formthi
            this.$http.post(apis.guestList, this.form).then((res) => {
                if (res.data.code === 0) {
                    this.tableData = res.data.rows
                    this.form.total = res.data.total
                    this.pageNum = res.data.pageNum
                }
                // console.log(res);
            })
        },
        clearnForm() {
            this.form = {
                time: '',
                phone: '',
                state: '',
                leaveTimeBegan: '',
                leaveTimeOver: '',
                pageNum: 1,
                pageSize: 10,
                total: 0,
            }
            this.searchEvent();
        },
        getGuestList() {
            this.$http.post(apis.guestList, this.form).then((res) => {
                if (res.data.code === 0) {
                    this.tableData = res.data.rows
                    this.form.total = res.data.total
                    this.pageNum = res.data.pageNum
                }
                // console.log(res);
            })
        },
    },
};
</script>

<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
.page-warp{
    padding: 20px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between
}
.btn{
    display: flex;
    justify-content: flex-end
}
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
</style>
