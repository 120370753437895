<!--
 * @Author: your name
 * @Date: 2021-11-24 10:16:18
 * @LastEditTime: 2021-11-26 16:15:38
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \onesports-arc-view\src\views\venueMana\guestBook\dialog\processResultDialog.vue
-->
<template>
    <div>
        <el-dialog
            title="留言操作"
            :visible.sync="show"
            width="30%"
            @open="openDialog"
            :before-close="closeDialog"
            center
            :close-on-click-modal="false">
                <el-form ref="form" :model="form" :rules="rules" label-width="150px">
                    <el-form-item label="处理结果：" prop="state">
                        <el-select v-model="form.state" placeholder="请选择" style="width:80%">
                            <el-option label="已处理" value="1"></el-option>
                            <el-option label="未处理" value="0"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="closeDialog">取 消</el-button>
                    <el-button type="primary" @click="sureEvent">确 定</el-button>
                </span>
        </el-dialog>
    </div>
</template>

<script>
import mixin from "@/mixins/dialogMixin";
import apis from "@/apis";

export default {
    mixins: [mixin],
    props: {
        show: {
            required: true,
            type: Boolean,
        },
        id: {
            required: true,
            type: String,
        },
    },
    data() {
        return {
            form: {
                state: '',
            },
            rules: {
                state: [
                    { required: true, message: '请选择处理结果', trigger: 'change' },
                ],
            },
        };
    },
    methods: {
        init() {
            this.$refs.form.resetFields()
        },
        sureEvent() {
            console.log(this.id)
            this.$http.get(`${apis.guestHandle}?id=${this.id}&state=${this.form.state}`).then((res) => {
                if (res.data.code === 0) {
                    this.$message.success('操作成功')
                    this.closeDialog()
                    this.init()
                }
            })
        },
        closeDialog() {

            this.$emit('closeDialog', false)
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
